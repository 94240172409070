import { render, staticRenderFns } from "./Migration.vue?vue&type=template&id=6a546bd7&scoped=true"
import script from "./Migration.vue?vue&type=script&lang=js"
export * from "./Migration.vue?vue&type=script&lang=js"
import style0 from "./Migration.vue?vue&type=style&index=0&id=6a546bd7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a546bd7",
  null
  
)

export default component.exports